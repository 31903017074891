<template>
  <div>
    <div v-if="bannerHome">
      <banner-large class="container-max-width" />
      <outfits-vitrina id="nav-box-outfit" class="container-max-width pt-3" />
      <nav-category id="nav-box-categories" class="container-max-width pt-3" />
      <breakin-cupo />
      <div
        v-for="(item, index) in vitrinasHome"
        :id="`vitrinas-home-${index}`"
        :key="index"
      >
        <carousel-products-banner
          v-if="item.banner"
          :products-analytics="item.vitrina.productos_analytics"
          :products="item.vitrina.items"
          :title="item.vitrina.title + ' ' + item.vitrina.slug"
          :img-u-r-l="item.banner"
          :bg-vitrina="item.background_color"
          :slug="item.vitrina.query"
          :position="item.vitrina.query"
        />
        <carousel-products
          v-else
          class="container-max-width"
          :products-analytics="item.vitrina.productos_analytics"
          :products="item.vitrina.items"
          :title="item.vitrina.title + ' ' + item.vitrina.slug"
          :slug="item.vitrina.query"
          :position="item.vitrina.query"
        />
      </div>
    </div>
    <div v-else>
      <skeleton-home />
    </div>
  </div>
</template>
<script>
import BannerLarge from '@/components/Home/BanerLarge.vue'
import NavCategory from '@/components/Home/NavCategory.vue'
import OutfitsVitrina from '@/components/Home/OutfitsVitrina.vue'
import BreakinCupo from '@/components/Templates/BreakinCupo.vue'
import CarouselProducts from '@/components/Templates/CarouselProducts.vue'
import CarouselProductsBanner from '@/components/Templates/CarouselProductsBanner.vue'
import SkeletonHome from '@/components/Home/SkeletonHome.vue'
import { GA4ViewPromotion } from '@/GA4/events'

import { mapState, mapActions } from 'vuex'
import { sendFBQEvent } from '@/fbq'
import { sendCriteoEventCriteo } from '@/criteo/events'
export default {
  name: 'Home',
  components: {
    BannerLarge,
    NavCategory,
    BreakinCupo,
    CarouselProducts,
    CarouselProductsBanner,
    SkeletonHome,
    OutfitsVitrina,
  },
  data() {
    return {
      showVitrinaOutfit: false,
      showVitrinaCategories: false,
      shiwVitrinas: [],
    }
  },
  computed: {
    ...mapState('layouts', [
      'bannerHome',
      'vitrinasHome',
      'outfitsVitrina',
      'categorysList',
    ]),
    ...mapState('user', ['dataUser']),
  },
  created() {
    sendFBQEvent(this, 'PageView', {})
  },
  mounted() {
    document.title =
      'Descubre las ultimas tendencias en moda femenina, ropa, zapatos y accesorios | DEREK Tienda Online'
    window.addEventListener('scroll', this.validateScrollHome)
    this.getHome()
    sendCriteoEventCriteo([{ event: 'viewHome' }])
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.validateScrollHome)
  },
  methods: {
    ...mapActions('layouts', ['getHome']),
    validateScrollHome() {
      const navBoxOutfit = document.getElementById('nav-box-outfit')
      if (
        navBoxOutfit &&
        !this.showVitrinaOutfit &&
        navBoxOutfit.getBoundingClientRect().top -
          (window.innerHeight - navBoxOutfit.offsetHeight / 2) <=
          0
      ) {
        this.showVitrinaOutfit = true
        this.outfitsVitrina.outfits.forEach(outfit => {
          GA4ViewPromotion({
            nameCreative: `home-${outfit.slug}`,
            slot: `Home-primary-outfits`,
            id: `${outfit.campania_slug}`,
            name: `${outfit.nombre}`,
            items: [],
          })
        })
      }
      const navBoxCategories = document.getElementById('nav-box-categories')
      if (
        navBoxCategories &&
        !this.showVitrinaCategories &&
        navBoxCategories.getBoundingClientRect().top -
          (window.innerHeight - navBoxCategories.offsetHeight / 2) <=
          0
      ) {
        this.showVitrinaCategories = true
        this.categorysList.forEach(category => {
          GA4ViewPromotion({
            nameCreative: `home-category-${category.tipoprenda_slug}`,
            slot: `Home-primary-categories`,
            id: `${category.tipoprenda_slug_singular}`,
            name: `${category.tipoprenda_texto}`,
            items: [],
          })
        })
      }
    },
  },
}
</script>
